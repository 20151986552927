/**
 * @file Meet your neighbors soho works
 * @author Alwyn Tan
 */

import React from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'
import Layout from '../../components/layout'
import logo from '../../images/logo.png'
import blackberry from '../../images/blackberry.png'
import SEO from '../../components/seo'
import ButtonV2 from '../../components/ButtonV2'

const FullScreenContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 30px;
`

const Container = styled.div`
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Logo = styled.img`
  height: 70px;
  object-fit: contain;
  filter: ${({ theme }) => (theme.isDarkMode ? 'invert()' : 'unset')};
`

const Section = styled.div`
  margin: 30px 0;

  h2 {
    position: relative;
    padding-bottom: 7px;
  }

  p {
    padding-left: 28px;
  }

  p:not(:last-child) {
    padding-bottom: 20px;
  }
`

const Blackberry = styled.img`
  height: 275px;
  width: 275px;
  max-width: 100%;
  object-fit: cover;

  border-radius: 10px;
`

const CoffeeChatInfo = () => {
  return (
    <Layout hideHeader>
      <SEO title="Solo | Meet Your Neighbor" />
      <FullScreenContainer>
        <Container>
          <Section>
            <Logo src={logo} />
          </Section>
          <h4 style={{ textAlign: 'center' }}>
            Welcome to the Soho Works Line. We’re connecting you with another
            Soho member every week for 15 mins.
          </h4>

          <Section>
            <Blackberry src={blackberry} />
          </Section>
          <Section>
            <h2>1. Register</h2>
            <p>
              Sign up for a Solo profile with your phone number and save ours in
              your phonebook ☎️
            </p>
            <h2>2. We’ll Call You</h2>
            <p>
              Subscribe and your phone will ring every week on Wednesday at 5pm
              PT and we’ll set you up with someone new.
              <br />
              <br />
              Not free this time? No problem. You can skip the call and we’ll
              ring you again next week. 📲
            </p>
            <h2>3. Rate the Call</h2>
            <p>
              Did you enjoy the chat? Shoot them a DM and stay in touch on the
              app. 📩
            </p>
          </Section>
          <ButtonV2 onClick={() => navigate('/app/calls/sohoworks')}>
            I'm In!
          </ButtonV2>
        </Container>
      </FullScreenContainer>
    </Layout>
  )
}

export default CoffeeChatInfo
